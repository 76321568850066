/* Tailwind CSS */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* PrimeNG */
@import '~primeicons/primeicons.css';
@import '~primeng/resources/themes/lara-light-indigo/theme.css';
@import '~primeng/resources/primeng.min.css';

/* Fonts */
@import './themes/fonts/poppins.scss';
@import './themes/fonts/icomoon.scss';

/* Quill (Wysiwyg) */
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css';

/* Theme */
@import './themes/tamiz.scss';
