.app-calendar-range {
  .app-calendar-list {
    font-size: 0.9rem;

    li {
      padding: 0.5rem 1rem;
      cursor: pointer;
      margin-bottom: 5px;
      border-radius: 5px;

      &.selected {
        background-color: var(--color-base-300);
        color: var(--color-base-content);
        font-weight: bold;
      }

      &:not(.selected):hover {
        background-color: var(--color-base-200);
      }
    }
  }

  .app-calendar {
    .p-datepicker {
      font-family: var(--font-family);
      font-size: 0.8rem;
      padding: 0;
      padding-left: 5px;
      background: var(--color-base-100, white);
      color: var(--color-base-content, black);
      border: 0;
      &:not(.p-disabled)
        table
        td
        span:not(.p-highlight):not(.p-disabled):focus {
        box-shadow: 0 0 0 0 var(--color-brand-100);
      }

      .p-datepicker-header {
        .p-datepicker-title {
          line-height: 1rem;
          .p-datepicker-month {
            color: var(--color-base-content);
            padding: 0;
            margin: 0;
            font-size: 0.9rem;
          }
        }
      }
      table {
        th,
        td {
          padding: 0;
        }

        th > span {
          font-weight: 300;
          color: var(--color-base-content);
          opacity: 0.5;
          font-size: 0.9rem;
        }

        td > span {
          width: 2rem;
          height: 2rem;
          font-size: 0.8rem;
          margin-right: 0.25rem;
          margin-bottom: 0.25rem;

          &.p-highlight {
            color: var(--color-base-content);
            background: var(--color-accent-200);
            font-weight: bold;
          }
        }
      }
    }
  }
}
