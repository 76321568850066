:root {
  --color-base-100: #ffffff;
  --color-base-200: #f6f6f6;
  --color-base-300: #f1f1f1; // BG
  --color-base-400: #edeff0;
  --color-base-500: #e9ecef;
  --color-base-600: #dce0e5;
  --color-base-700: #ced4da; // Border
  --color-base-800: #9ba2aa;
  --color-base-900: #6c757d;
  --color-base-content: #333333;

  --color-brand-50: #fffbe5;
  --color-brand-100: #fff6cc;
  --color-brand-200: #ffefa3;
  --color-brand-300: #ffe87a;
  --color-brand-400: #ffe152;
  --color-brand-500: #ffd300;
  --color-brand-600: #d6b100;
  --color-brand-700: #ad8f00;
  --color-brand-800: #856e00;
  --color-brand-900: #5c4c00;
  --color-brand-content: #333333;

  --color-primary-100: #686868;
  --color-primary-200: #4f4f4f;
  --color-primary-300: #363636;
  --color-primary-400: #1d1d1d;
  --color-primary-500: #040404;
  --color-primary-600: #030303;
  --color-primary-700: #020202;
  --color-primary-800: #010101;
  --color-primary-900: #000000;
  --color-primary-content: #ffffff;

  --color-secondary-100: #959b9d;
  --color-secondary-200: #848c8e;
  --color-secondary-300: #808889;
  --color-secondary-400: #767d7f;
  --color-secondary-500: #6c757d;
  --color-secondary-600: #5b636a;
  --color-secondary-700: #4b5157;
  --color-secondary-800: #3a3e42;
  --color-secondary-900: #2c2f31;
  --color-secondary-content: #ffffff;

  --color-accent-100: #fff8f0;
  --color-accent-200: #ffe6ca;
  --color-accent-300: #ffc499;
  --color-accent-400: #ffa666;
  --color-accent-500: #ff6b00;
  --color-accent-600: #cc5600;
  --color-accent-700: #994000;
  --color-accent-800: #662b00;
  --color-accent-900: #331500;
  --color-accent-content: #ffffff;

  --color-neutral-100: #33ccff;
  --color-neutral-200: #1fc7ff;
  --color-neutral-300: #0ac2ff;
  --color-neutral-400: #00b8f5;
  --color-neutral-500: #00a7e1;
  --color-neutral-600: #00a8e0;
  --color-neutral-700: #0099cc;
  --color-neutral-800: #008ab8;
  --color-neutral-900: #007aa3;
  --color-neutral-content: #ffffff;

  --color-info-50: #eff6ff;
  --color-info-100: #dceafd;
  --color-info-200: #abcdfb;
  --color-info-300: #7ab1f9;
  --color-info-400: #4a94f6;
  --color-info-500: #1a73e8;
  --color-info-600: #08499f;
  --color-info-700: #05336f;
  --color-info-800: #031c3e;
  --color-info-900: #01060d;
  --color-info-content: #ffffff;

  --color-success-50: #e7fcf2;
  --color-success-75: #def4e4;
  --color-success-100: #89e1b7;
  --color-success-200: #71daa8;
  --color-success-300: #59d499;
  --color-success-400: #41ce8a;
  --color-success-500: #2ba66b;
  --color-success-600: #258e5b;
  --color-success-700: #1e754c;
  --color-success-800: #185d3c;
  --color-success-900: #12452c;
  --color-success-content: #ffffff;

  --color-warning-100: #fdf7e0;
  --color-warning-200: #fbf0c3;
  --color-warning-300: #f9e9a6;
  --color-warning-400: #f8e28a;
  --color-warning-500: #f4d450;
  --color-warning-600: #f2cd33;
  --color-warning-700: #f0c616;
  --color-warning-800: #dab30e;
  --color-warning-900: #be9b0c;
  --color-warning-content: #202020;

  --color-error-50: #fff9f9;
  --color-error-75: #fed4d6;
  --color-error-100: #e58383;
  --color-error-200: #e17272;
  --color-error-300: #de6262;
  --color-error-400: #da5151;
  --color-error-500: #d32f2f;
  --color-error-600: #c42929;
  --color-error-700: #b32626;
  --color-error-800: #a22222;
  --color-error-900: #921f1f;
  --color-error-content: #ffffff;

  /* General */
  --border-radius: 0.2rem;
  --font-family: Poppins, 'Open Sans', sans-serif;
  --font-size: 0.9rem;

  /* Input */
  --input-bg-color: var(--color-base-100);
  --input-border-color: var(--color-base-800);
  --input-border-radius: var(--border-radius);
  --input-color: var(--color-base-content);
  --input-padding: 0.25rem 0.75rem;

  /* Label */
  --label-color: var(--color-base-900);
  --label-font-size: 12px;
  --label-font-weight: 400;
  --label-margin-bottom: 0.25rem;
  --label-text-transform: uppercase;

  /* Select */
  --select-bg-color: var(--color-base-100);
  --select-border-color: var(--color-base-800);
  --select-border-radius: var(--border-radius);
  --select-color: var(--color-base-content);
  --select-padding: 0.5rem 0.75rem;

  /* Title */
  --title-font-family: var(--font-family);
  --title-font-size: 32px;
  --title-font-weight: 700;

  /* Subtitle */
  --subtitle-font-family: var(--font-family);
  --subtitle-font-size: 20px;
  --subtitle-font-weight: 600;
  --subtitle-color: var(--color-base-content);

  /* Switch */
  --switch-checked-bg: var(--color-accent-500);
  --switch-checked-button-color: var(--color-base-100);
  --switch-checked-border-color: var(--color-accent-500);
  --switch-unchecked-bg: var(--color-base-800);
  --switch-unchecked-button-color: var(--color-base-100);
  --switch-unchecked-border-color: var(--color-base-800);
  --switch-border-radius: 2rem;
}

html,
body {
  @apply h-full;
}

body {
  background-color: var(--color-base-700);
  color: var(--color-base-content);
  font-family: var(--font-family);
  font-size: var(--font-size);
}

/* Kutty overwrite */
.form-error {
  @apply text-error-500 text-xs mt-1;
}

/* PrimeNG custom component */
.p-overlaypanel .p-overlaypanel-content {
  padding: 0;
}
@import '../app/components/filter/filter-calendar-range/filter-calendar-range.component.scss';
@import '../app/components/filter/filter-checklist/filter-checklist.component.scss';
@import '../app/components/filter/filter-price-range/filter-price-range.component.scss';
@import '../app/components/tooltip/tooltip/tooltip.component.scss';

// Message dialog
.p-toast .p-toast-message {
  margin: 0 0 1rem 0;
  box-shadow: none;
  border-radius: 3px;

  .p-toast-message-content {
    padding: 1rem;
    border-width: 0;

    .p-toast-message-icon {
      font-size: 1.5rem;
      padding: 7px;
    }
    .p-toast-detail {
      font-size: 0.9rem;
    }
  }

  &.p-toast-message-success {
    background: var(--color-success-500);
    border: none;
    color: var(--color-success-content);
    .p-toast-message-icon,
    .p-toast-icon-close {
      color: var(--color-success-content);
    }
  }

  &.p-toast-message-error {
    background: var(--color-error-500);
    border: none;
    color: var(--color-error-content);
    .p-toast-message-icon,
    .p-toast-icon-close {
      color: var(--color-error-content);
    }
  }
}

app-form-label {
  .app-label {
    overflow: hidden !important;
    display: -webkit-box !important;
    -webkit-box-orient: vertical !important;
    -webkit-line-clamp: 1 !important;
  }
}

app-tooltip {
  display: flex;
}

.text-shadowed {
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
}
