.app-filter-price {
  .p-slider {
    .p-slider-range {
      background: var(--color-accent-500);
    }

    .p-slider-handle {
      border-color: var(--color-accent-500);
    }

    &:not(.p-disabled) .p-slider-handle:hover {
      background: var(--color-accent-500);
      border-color: var(--color-accent-500);
    }
  }
}
