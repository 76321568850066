@font-face {
  font-family: Poppins;
  font-weight: 100;
  src: url('/assets/fonts/Poppins/Poppins-Thin.ttf');
}

@font-face {
  font-family: Poppins;
  font-weight: 200;
  src: url('/assets/fonts/Poppins/Poppins-ExtraLight.ttf');
}

@font-face {
  font-family: Poppins;
  font-weight: 300;
  src: url('/assets/fonts/Poppins/Poppins-Light.ttf');
}

@font-face {
  font-family: Poppins;
  font-weight: 400;
  src: url('/assets/fonts/Poppins/Poppins-Regular.ttf');
}

@font-face {
  font-family: Poppins;
  font-weight: 500;
  src: url('/assets/fonts/Poppins/Poppins-Medium.ttf');
}

@font-face {
  font-family: Poppins;
  font-weight: 600;
  src: url('/assets/fonts/Poppins/Poppins-SemiBold.ttf');
}

@font-face {
  font-family: Poppins;
  font-weight: 700;
  src: url('/assets/fonts/Poppins/Poppins-Bold.ttf');
}

@font-face {
  font-family: Poppins;
  font-weight: 800;
  src: url('/assets/fonts/Poppins/Poppins-ExtraBold.ttf');
}

@font-face {
  font-family: Poppins;
  font-weight: 900;
  src: url('/assets/fonts/Poppins/Poppins-Black.ttf');
}
