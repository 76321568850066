@font-face {
  font-family: 'icomoon';
  src:
    url('/assets/fonts/Icomoon/icomoon.eot') format('embedded-opentype'),
    url('/assets/fonts/Icomoon/icomoon.ttf') format('truetype'),
    url('/assets/fonts/Icomoon/icomoon.woff') format('woff'),
    url('/assets/fonts/Icomoon/icomoon.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  font-size: 1.1rem;
  text-transform: none;
  line-height: 1;

  align-self: center;
  display: flex;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^='icon-']:before,
[class*=' icon-']:before {
  color: inherit !important;
}

.icon-message-circle:before {
  content: '\eab9';
}
.icon-Share:before {
  content: '\eab6';
}
.icon-thumbs-down-fill:before {
  content: '\eab7';
}
.icon-Icon-arrow-down:before {
  content: '\eab8';
}
.icon-activity:before {
  content: '\e900';
}
.icon-airplay:before {
  content: '\e901';
}
.icon-alert-circle:before {
  content: '\e902';
}
.icon-alert-octagon:before {
  content: '\e903';
}
.icon-alert-triangle:before {
  content: '\e904';
}
.icon-align-center:before {
  content: '\e905';
}
.icon-align-justify:before {
  content: '\e906';
}
.icon-align-left:before {
  content: '\e907';
}
.icon-align-right:before {
  content: '\e908';
}
.icon-anchor:before {
  content: '\e909';
}
.icon-archive:before {
  content: '\e90a';
}
.icon-arrow-down-circle:before {
  content: '\e90b';
}
.icon-arrow-down-left:before {
  content: '\e90c';
}
.icon-arrow-down-right:before {
  content: '\e90d';
}
.icon-arrow-down:before {
  content: '\eab8';
}
.icon-arrow-left-circle:before {
  content: '\e90e';
}
.icon-arrow-right-circle:before {
  content: '\e90f';
}
.icon-arrow-right:before {
  content: '\e910';
}
.icon-arrow-up-circle:before {
  content: '\e911';
}
.icon-arrow-up-left:before {
  content: '\e912';
}
.icon-arrow-up-right:before {
  content: '\e913';
}
.icon-arrow-up:before {
  content: '\e914';
}
.icon-asterisk:before {
  content: '\e915';
}
.icon-at-sign:before {
  content: '\e916';
}
.icon-award:before {
  content: '\e917';
}
.icon-bar-chart-2:before {
  content: '\e918';
}
.icon-bar-chart:before {
  content: '\e919';
}
.icon-battery-full:before {
  content: '\e91a';
}
.icon-battery-low:before {
  content: '\e91b';
}
.icon-battery-medium:before {
  content: '\e91c';
}
.icon-battery-charging:before {
  content: '\e91d';
}
.icon-battery:before {
  content: '\e91e';
}
.icon-Bell-minus:before {
  content: '\e91f';
}
.icon-Bell-plus:before {
  content: '\e920';
}
.icon-Bell-ring:before {
  content: '\e921';
}
.icon-bell:before {
  content: '\e922';
}
.icon-Bike:before {
  content: '\e923';
}
.icon-Binary:before {
  content: '\e924';
}
.icon-Bitcoin:before {
  content: '\e925';
}
.icon-bluetooth-off:before {
  content: '\e926';
}
.icon-book-open:before {
  content: '\e927';
}
.icon-book:before {
  content: '\e928';
}
.icon-bookmark-minus:before {
  content: '\e929';
}
.icon-bookmark-plus:before {
  content: '\e92a';
}
.icon-bookmark:before {
  content: '\e92b';
}
.icon-bot:before {
  content: '\e92c';
}
.icon-box-slect:before {
  content: '\e92d';
}
.icon-box:before {
  content: '\e92e';
}
.icon-briefcase:before {
  content: '\e92f';
}
.icon-brush:before {
  content: '\e930';
}
.icon-Bug:before {
  content: '\e931';
}
.icon-bulding-1:before {
  content: '\e932';
}
.icon-bulding-2:before {
  content: '\e933';
}
.icon-calculator:before {
  content: '\e934';
}
.icon-calendar:before {
  content: '\e935';
}
.icon-camera-off:before {
  content: '\e936';
}
.icon-camera:before {
  content: '\e937';
}
.icon-cast:before {
  content: '\e938';
}
.icon-check-circle-1:before {
  content: '\e939';
}
.icon-check-circle-2:before {
  content: '\e93a';
}
.icon-check-square:before {
  content: '\e93b';
}
.icon-check:before {
  content: '\e93c';
}
.icon-chevron-down:before {
  content: '\e93d';
}
.icon-chevron-filled-down:before {
  content: '\e93e';
}
.icon-chevron-filled-up:before {
  content: '\e93f';
}
.icon-chevron-first:before {
  content: '\e940';
}
.icon-chevron-last:before {
  content: '\e941';
}
.icon-chevron-left:before {
  content: '\e942';
}
.icon-chevron-opposite:before {
  content: '\e943';
}
.icon-chevron-right-up:before {
  content: '\e944';
}
.icon-chevron-right:before {
  content: '\e945';
}
.icon-chevron-up:before {
  content: '\e946';
}
.icon-chevrons-faced:before {
  content: '\e947';
}
.icon-chevrons-down:before {
  content: '\e948';
}
.icon-chevrons-left:before {
  content: '\e949';
}
.icon-chevrons-right-left:before {
  content: '\e94a';
}
.icon-chevrons-right:before {
  content: '\e94b';
}
.icon-chevrons-up-down:before {
  content: '\e94c';
}
.icon-chevrons-up:before {
  content: '\e94d';
}
.icon-circle-slashed:before {
  content: '\e94e';
}
.icon-circle-stop:before {
  content: '\e94f';
}
.icon-circle:before {
  content: '\e950';
}
.icon-clapperboard:before {
  content: '\e951';
}
.icon-clipboard-check:before {
  content: '\e952';
}
.icon-clipboard-copy:before {
  content: '\e953';
}
.icon-clipboard-list:before {
  content: '\e954';
}
.icon-clipboard-x:before {
  content: '\e955';
}
.icon-clipboard:before {
  content: '\e956';
}
.icon-clock-1:before {
  content: '\e957';
}
.icon-clock-2:before {
  content: '\e958';
}
.icon-clock-3:before {
  content: '\e959';
}
.icon-clock-4:before {
  content: '\e95a';
}
.icon-clock-5:before {
  content: '\e95b';
}
.icon-clock-6:before {
  content: '\e95c';
}
.icon-clock-7:before {
  content: '\e95d';
}
.icon-clock-8:before {
  content: '\e95e';
}
.icon-clock-9:before {
  content: '\e95f';
}
.icon-clock-10:before {
  content: '\e960';
}
.icon-clock-11:before {
  content: '\e961';
}
.icon-clock-12:before {
  content: '\e962';
}
.icon-cloud-drizzle:before {
  content: '\e963';
}
.icon-cloud-moon:before {
  content: '\e964';
}
.icon-cloud-rain-wind:before {
  content: '\e965';
}
.icon-cloud-rain:before {
  content: '\e966';
}
.icon-cloud-snow:before {
  content: '\e967';
}
.icon-cloud-sun:before {
  content: '\e968';
}
.icon-code-terminal:before {
  content: '\e969';
}
.icon-code-2:before {
  content: '\e96a';
}
.icon-code:before {
  content: '\e96b';
}
.icon-codepen:before {
  content: '\e96c';
}
.icon-codesandbox:before {
  content: '\e96d';
}
.icon-coins:before {
  content: '\e96e';
}
.icon-columns:before {
  content: '\e96f';
}
.icon-command:before {
  content: '\e970';
}
.icon-compass:before {
  content: '\e971';
}
.icon-construction-helmet:before {
  content: '\e972';
}
.icon-contact:before {
  content: '\e973';
}
.icon-contrast:before {
  content: '\e974';
}
.icon-cookie:before {
  content: '\e975';
}
.icon-copy:before {
  content: '\e976';
}
.icon-copyleft:before {
  content: '\e977';
}
.icon-copyright:before {
  content: '\e978';
}
.icon-corner-down-left:before {
  content: '\e979';
}
.icon-corner-down-right:before {
  content: '\e97a';
}
.icon-corner-left-down:before {
  content: '\e97b';
}
.icon-corner-left-up:before {
  content: '\e97c';
}
.icon-corner-right-down:before {
  content: '\e97d';
}
.icon-corner-right-up:before {
  content: '\e97e';
}
.icon-corner-up-left:before {
  content: '\e97f';
}
.icon-corner-up-right:before {
  content: '\e980';
}
.icon-cpu:before {
  content: '\e981';
}
.icon-crop:before {
  content: '\e982';
}
.icon-cross:before {
  content: '\e983';
}
.icon-crosshair:before {
  content: '\e984';
}
.icon-crown:before {
  content: '\e985';
}
.icon-database:before {
  content: '\e986';
}
.icon-delete:before {
  content: '\e987';
}
.icon-dice-1:before {
  content: '\e988';
}
.icon-dice-2:before {
  content: '\e989';
}
.icon-dice-3:before {
  content: '\e98a';
}
.icon-dice-4:before {
  content: '\e98b';
}
.icon-dice-5:before {
  content: '\e98c';
}
.icon-dice-6:before {
  content: '\e98d';
}
.icon-disc:before {
  content: '\e98e';
}
.icon-divide-2:before {
  content: '\e98f';
}
.icon-divide-circle:before {
  content: '\e990';
}
.icon-divide-square:before {
  content: '\e991';
}
.icon-divide:before {
  content: '\e992';
}
.icon-dollar-sign:before {
  content: '\e993';
}
.icon-download-cloud:before {
  content: '\e994';
}
.icon-download:before {
  content: '\e995';
}
.icon-dribbble:before {
  content: '\e996';
}
.icon-droplet:before {
  content: '\e997';
}
.icon-droplets:before {
  content: '\e998';
}
.icon-edit-1:before {
  content: '\e999';
}
.icon-edit-2:before {
  content: '\e99a';
}
.icon-edit-3:before {
  content: '\e99b';
}
.icon-edit-4:before {
  content: '\e99c';
}
.icon-egg:before {
  content: '\e99d';
}
.icon-equal-not:before {
  content: '\e99e';
}
.icon-equal:before {
  content: '\e99f';
}
.icon-eraser:before {
  content: '\e9a0';
}
.icon-euro:before {
  content: '\e9a1';
}
.icon-expand:before {
  content: '\e9a2';
}
.icon-external-link:before {
  content: '\e9a3';
}
.icon-eye:before {
  content: '\e9a4';
}
.icon-face-frown:before {
  content: '\e9a5';
}
.icon-face-meh:before {
  content: '\e9a6';
}
.icon-face-smile:before {
  content: '\e9a7';
}
.icon-facebook:before {
  content: '\e9a8';
}
.icon-fast-forward:before {
  content: '\e9a9';
}
.icon-feather:before {
  content: '\e9aa';
}
.icon-figma:before {
  content: '\e9ab';
}
.icon-file-check-1:before {
  content: '\e9ac';
}
.icon-file-check-2:before {
  content: '\e9ad';
}
.icon-file-code:before {
  content: '\e9ae';
}
.icon-file-digit:before {
  content: '\e9af';
}
.icon-file-input:before {
  content: '\e9b0';
}
.icon-file-minus-2:before {
  content: '\e9b1';
}
.icon-file-minus:before {
  content: '\e9b2';
}
.icon-file-output:before {
  content: '\e9b3';
}
.icon-file-plus-2:before {
  content: '\e9b4';
}
.icon-file-plus:before {
  content: '\e9b5';
}
.icon-file-text:before {
  content: '\e9b6';
}
.icon-file:before {
  content: '\e9b7';
}
.icon-files:before {
  content: '\e9b8';
}
.icon-film:before {
  content: '\e9b9';
}
.icon-filter:before {
  content: '\e9ba';
}
.icon-flag-triangle-left:before {
  content: '\e9bb';
}
.icon-flag-triangle-right:before {
  content: '\e9bc';
}
.icon-flashlight-off:before {
  content: '\e9bd';
}
.icon-flashlight:before {
  content: '\e9be';
}
.icon-flask-round:before {
  content: '\e9bf';
}
.icon-folder-minus:before {
  content: '\e9c0';
}
.icon-folder-open:before {
  content: '\e9c1';
}
.icon-folder-plus:before {
  content: '\e9c2';
}
.icon-folder:before {
  content: '\e9c3';
}
.icon-food-1:before {
  content: '\e9c4';
}
.icon-form-input:before {
  content: '\e9c5';
}
.icon-framer:before {
  content: '\e9c6';
}
.icon-gauge:before {
  content: '\e9c7';
}
.icon-gavel:before {
  content: '\e9c8';
}
.icon-gem:before {
  content: '\e9c9';
}
.icon-ghost:before {
  content: '\e9ca';
}
.icon-gift:before {
  content: '\e9cb';
}
.icon-github:before {
  content: '\e9cc';
}
.icon-gitlab:before {
  content: '\e9cd';
}
.icon-glasses:before {
  content: '\e9ce';
}
.icon-globe-2:before {
  content: '\e9cf';
}
.icon-globe:before {
  content: '\e9d0';
}
.icon-graduation-cap:before {
  content: '\e9d1';
}
.icon-grid:before {
  content: '\e9d2';
}
.icon-grip-horizontal:before {
  content: '\e9d3';
}
.icon-grip-vertical:before {
  content: '\e9d4';
}
.icon-hand-grab:before {
  content: '\e9d5';
}
.icon-hand-metal:before {
  content: '\e9d6';
}
.icon-hand-pointer:before {
  content: '\e9d7';
}
.icon-hand:before {
  content: '\e9d8';
}
.icon-hard-drive:before {
  content: '\e9d9';
}
.icon-hash:before {
  content: '\e9da';
}
.icon-headphones:before {
  content: '\e9db';
}
.icon-heart-liked:before {
  content: '\e9dc';
  color: #333;
}
.icon-heart-off:before {
  content: '\e9dd';
}
.icon-heart:before {
  content: '\e9de';
  color: #333;
}
.icon-help:before {
  content: '\e9df';
}
.icon-hexagon:before {
  content: '\e9e0';
}
.icon-highlight:before {
  content: '\e9e1';
}
.icon-history:before {
  content: '\e9e2';
}
.icon-home:before {
  content: '\e9e3';
}
.icon-hourglass:before {
  content: '\e9e4';
}
.icon-human:before {
  content: '\e9e5';
}
.icon-image-minus:before {
  content: '\e9e6';
}
.icon-image-off:before {
  content: '\e9e7';
}
.icon-image-plus:before {
  content: '\e9e8';
}
.icon-image:before {
  content: '\e9e9';
}
.icon-import:before {
  content: '\e9ea';
}
.icon-inbox:before {
  content: '\e9eb';
}
.icon-Indent:before {
  content: '\e9ec';
}
.icon-info:before {
  content: '\e9ed';
}
.icon-Inspect:before {
  content: '\e9ee';
}
.icon-instagram:before {
  content: '\e9ef';
}
.icon-italic:before {
  content: '\e9f0';
}
.icon-joystick-1:before {
  content: '\e9f1';
}
.icon-key:before {
  content: '\e9f2';
}
.icon-keyboard:before {
  content: '\e9f3';
}
.icon-landmark:before {
  content: '\e9f4';
}
.icon-languages:before {
  content: '\e9f5';
}
.icon-laptop-2:before {
  content: '\e9f6';
}
.icon-lasso-select:before {
  content: '\e9f7';
}
.icon-lasso:before {
  content: '\e9f8';
}
.icon-layers:before {
  content: '\e9f9';
}
.icon-layout-dashboard:before {
  content: '\e9fa';
}
.icon-layout-grid:before {
  content: '\e9fb';
}
.icon-layout-list .path1:before {
  content: '\e9fc';
  color: rgb(245, 245, 245);
}
.icon-layout-list .path2:before {
  content: '\e9fd';
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.icon-layout-template:before {
  content: '\e9fe';
}
.icon-layout:before {
  content: '\e9ff';
}
.icon-library:before {
  content: '\ea00';
}
.icon-life-buoy:before {
  content: '\ea01';
}
.icon-lightbulb:before {
  content: '\ea02';
}
.icon-link-2-off:before {
  content: '\ea03';
}
.icon-link-2:before {
  content: '\ea04';
}
.icon-link:before {
  content: '\ea05';
}
.icon-linkedin:before {
  content: '\ea06';
}
.icon-list-checks:before {
  content: '\ea07';
}
.icon-list-minus:before {
  content: '\ea08';
}
.icon-list-ordered:before {
  content: '\ea09';
}
.icon-list-plus:before {
  content: '\ea0a';
}
.icon-list-x:before {
  content: '\ea0b';
}
.icon-list:before {
  content: '\ea0c';
}
.icon-loader-2:before {
  content: '\ea0d';
}
.icon-loader:before {
  content: '\ea0e';
}
.icon-locate-fixed:before {
  content: '\ea0f';
}
.icon-locate:before {
  content: '\ea10';
}
.icon-lock:before {
  content: '\ea11';
}
.icon-log-in:before {
  content: '\ea12';
}
.icon-log-out:before {
  content: '\ea13';
}
.icon-mail:before {
  content: '\ea14';
}
.icon-map-pin:before {
  content: '\ea15';
}
.icon-map:before {
  content: '\ea16';
}
.icon-maximize-2:before {
  content: '\ea17';
}
.icon-maximize:before {
  content: '\ea18';
}
.icon-menu:before {
  content: '\ea19';
}
.icon-message-square:before {
  content: '\ea1a';
}
.icon-mic:before {
  content: '\ea1b';
}
.icon-minimize-2:before {
  content: '\ea1c';
}
.icon-minimize:before {
  content: '\ea1d';
}
.icon-minus-circle:before {
  content: '\ea1e';
}
.icon-minus-square:before {
  content: '\ea1f';
}
.icon-minus:before {
  content: '\ea20';
}
.icon-monitor-no:before {
  content: '\ea21';
}
.icon-monitor:before {
  content: '\ea22';
}
.icon-moon:before {
  content: '\ea23';
}
.icon-more-horizontal:before {
  content: '\ea24';
}
.icon-more-vertical:before {
  content: '\ea25';
}
.icon-mountain-snow:before {
  content: '\ea26';
}
.icon-mountain:before {
  content: '\ea27';
}
.icon-mouse-pointer-2:before {
  content: '\ea28';
}
.icon-mouse-pointer-click:before {
  content: '\ea29';
}
.icon-mouse-pointer:before {
  content: '\ea2a';
}
.icon-mouse:before {
  content: '\ea2b';
}
.icon-move-diagonal-2:before {
  content: '\ea2c';
}
.icon-move-diagonal:before {
  content: '\ea2d';
}
.icon-move-horizontal:before {
  content: '\ea2e';
}
.icon-move-vertical:before {
  content: '\ea2f';
}
.icon-move:before {
  content: '\ea30';
}
.icon-mug:before {
  content: '\ea31';
}
.icon-music:before {
  content: '\ea32';
}
.icon-navigation-2:before {
  content: '\ea33';
}
.icon-navigation:before {
  content: '\ea34';
}
.icon-octagon:before {
  content: '\ea35';
}
.icon-package-check:before {
  content: '\ea36';
}
.icon-package-minus:before {
  content: '\ea37';
}
.icon-package-plus:before {
  content: '\ea38';
}
.icon-package-search:before {
  content: '\ea39';
}
.icon-package-x:before {
  content: '\ea3a';
}
.icon-package:before {
  content: '\ea3b';
}
.icon-palette:before {
  content: '\ea3c';
}
.icon-palmtree:before {
  content: '\ea3d';
}
.icon-paperclip:before {
  content: '\ea3e';
}
.icon-pause-circle:before {
  content: '\ea3f';
}
.icon-pause-octagon:before {
  content: '\ea40';
}
.icon-pause:before {
  content: '\ea41';
}
.icon-pen-tool:before {
  content: '\ea42';
}
.icon-percent:before {
  content: '\ea43';
}
.icon-pie-chart:before {
  content: '\ea44';
}
.icon-piggy-bank:before {
  content: '\ea45';
}
.icon-pin:before {
  content: '\ea46';
}
.icon-pipette:before {
  content: '\ea47';
}
.icon-plane:before {
  content: '\ea48';
}
.icon-play-circle:before {
  content: '\ea49';
}
.icon-play:before {
  content: '\ea4a';
}
.icon-plug-zap:before {
  content: '\ea4b';
}
.icon-plus-circle:before {
  content: '\ea4c';
}
.icon-plus-square:before {
  content: '\ea4d';
}
.icon-plus:before {
  content: '\ea4e';
}
.icon-pocket:before {
  content: '\ea4f';
}
.icon-power:before {
  content: '\ea50';
}
.icon-printer:before {
  content: '\ea51';
}
.icon-promo:before {
  content: '\ea52';
}
.icon-puzzle:before {
  content: '\ea53';
}
.icon-qr-code:before {
  content: '\ea54';
}
.icon-quote:before {
  content: '\ea55';
}
.icon-radio-receiver:before {
  content: '\ea56';
}
.icon-radio:before {
  content: '\ea57';
}
.icon-redo:before {
  content: '\ea58';
}
.icon-refresh-ccw:before {
  content: '\ea59';
}
.icon-refresh-cw:before {
  content: '\ea5a';
}
.icon-regex:before {
  content: '\ea5b';
}
.icon-repeat-1:before {
  content: '\ea5c';
}
.icon-repeat-all:before {
  content: '\ea5d';
}
.icon-repeat:before {
  content: '\ea5e';
}
.icon-reply:before {
  content: '\ea5f';
}
.icon-rewind:before {
  content: '\ea60';
}
.icon-rocket:before {
  content: '\ea61';
}
.icon-rotate-ccw:before {
  content: '\ea62';
}
.icon-rotate-cw:before {
  content: '\ea63';
}
.icon-rss:before {
  content: '\ea64';
}
.icon-save:before {
  content: '\ea65';
}
.icon-scale:before {
  content: '\ea66';
}
.icon-scan-line:before {
  content: '\ea67';
}
.icon-scan:before {
  content: '\ea68';
}
.icon-scissors:before {
  content: '\ea69';
}
.icon-screen-share-off:before {
  content: '\ea6a';
}
.icon-screen-share:before {
  content: '\ea6b';
}
.icon-Search:before {
  content: '\ea6c';
}
.icon-server:before {
  content: '\ea6d';
}
.icon-settings:before {
  content: '\ea6e';
}
.icon-share:before {
  content: '\ea6f';
}
.icon-shield-off:before {
  content: '\ea70';
}
.icon-shield:before {
  content: '\ea71';
}
.icon-shopping-bag:before {
  content: '\ea72';
}
.icon-shuffle:before {
  content: '\ea73';
}
.icon-sidebar:before {
  content: '\ea74';
}
.icon-sign:before {
  content: '\ea75';
}
.icon-skip-back:before {
  content: '\ea76';
}
.icon-skip-forward:before {
  content: '\ea77';
}
.icon-slack:before {
  content: '\ea78';
}
.icon-slash:before {
  content: '\ea79';
}
.icon-sliders:before {
  content: '\ea7a';
}
.icon-smartphone:before {
  content: '\ea7b';
}
.icon-speaker:before {
  content: '\ea7c';
}
.icon-square:before {
  content: '\ea7d';
}
.icon-star:before {
  content: '\ea7e';
}
.icon-share-2:before {
  content: '\ea7f';
}
.icon-sun-haze:before {
  content: '\ea80';
}
.icon-sun:before {
  content: '\ea81';
}
.icon-sunrise:before {
  content: '\ea82';
}
.icon-sunset:before {
  content: '\ea83';
}
.icon-table:before {
  content: '\ea84';
}
.icon-tablet:before {
  content: '\ea85';
}
.icon-tag:before {
  content: '\ea86';
}
.icon-target:before {
  content: '\ea87';
}
.icon-thermometer:before {
  content: '\ea88';
}
.icon-thumbs-down:before {
  content: '\ea89';
}
.icon-thumbs-up:before {
  content: '\ea8a';
}
.icon-ticket:before {
  content: '\ea8b';
}
.icon-toggle-left:before {
  content: '\ea8c';
}
.icon-toggle-right:before {
  content: '\ea8d';
}
.icon-tool:before {
  content: '\ea8e';
}
.icon-trash-1:before {
  content: '\ea8f';
}
.icon-trash-2:before {
  content: '\ea90';
}
.icon-trello:before {
  content: '\ea91';
}
.icon-trending-down:before {
  content: '\ea92';
}
.icon-trending-up:before {
  content: '\ea93';
}
.icon-triangle:before {
  content: '\ea94';
}
.icon-troley:before {
  content: '\ea95';
}
.icon-tv:before {
  content: '\ea96';
}
.icon-twitch:before {
  content: '\ea97';
}
.icon-twitter:before {
  content: '\ea98';
}
.icon-type:before {
  content: '\ea99';
}
.icon-umbrella:before {
  content: '\ea9a';
}
.icon-underline:before {
  content: '\ea9b';
}
.icon-upload-cloud:before {
  content: '\ea9c';
}
.icon-upload:before {
  content: '\ea9d';
}
.icon-user-check:before {
  content: '\ea9e';
}
.icon-user-minus:before {
  content: '\ea9f';
}
.icon-user-plus:before {
  content: '\eaa0';
}
.icon-user:before {
  content: '\eaa1';
}
.icon-users:before {
  content: '\eaa2';
}
.icon-video-off:before {
  content: '\eaa3';
}
.icon-video:before {
  content: '\eaa4';
}
.icon-voicemail:before {
  content: '\eaa5';
}
.icon-volume-1:before {
  content: '\eaa6';
}
.icon-volume-2:before {
  content: '\eaa7';
}
.icon-volume-x:before {
  content: '\eaa8';
}
.icon-volume:before {
  content: '\eaa9';
}
.icon-wifi-off:before {
  content: '\eaaa';
}
.icon-wifi:before {
  content: '\eaab';
}
.icon-wind:before {
  content: '\eaac';
}
.icon-x-circle:before {
  content: '\eaad';
}
.icon-x-octagon:before {
  content: '\eaae';
}
.icon-x-square:before {
  content: '\eaaf';
}
.icon-x:before {
  content: '\eab0';
}
.icon-youtube:before {
  content: '\eab1';
}
.icon-zap-off:before {
  content: '\eab2';
}
.icon-zap:before {
  content: '\eab3';
}
.icon-zoom-in:before {
  content: '\eab4';
}
.icon-zoom-out:before {
  content: '\eab5';
}
