.app-filter-checklist-search,
.app-filter-price-input {
  width: 100%;

  .p-inputtext {
    width: 100%;
    font-size: 0.875rem /* 14px */;
    line-height: 1.25rem /* 20px */;
    color: var(--color-base-content);
    background-color: var(--color-base-100);
    font-family: var(--font-family);
    border-color: var(--color-base-800);
    border-radius: 5px;

    &:enabled:focus {
      box-shadow: 0 0 0 0.2rem var(--color-accent-50);
      border-color: var(--color-info-500);
    }
  }
}

.app-filter-checklist {
  .p-checkbox-label {
    line-height: 1.3rem;
    margin-left: 1.5rem;
  }

  .p-checkbox {
    .p-checkbox-label {
      font-size: 0.5rem;
    }
    &:not(.p-checkbox-disabled) .p-checkbox-box:hover {
      border-color: var(--color-accent-500, blue);
    }
    &:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
      border-color: var(--color-accent-500, blue);
      background: var(--color-accent-500, blue);
      color: #ffffff;
    }
    .p-checkbox-box.p-highlight {
      border-color: var(--color-accent-500, blue);
      background: var(--color-accent-500, blue);
    }
  }
  .p-checkbox .p-checkbox-box {
    border: 1px solid var(--color-base-800);
  }
}
